<template>
  <div>
    <h2>{{ d.name }}</h2>
    <v-data-table
      :items="athletes"
      :headers="[
        { text: 'Turner', value: 'name', sortable: false },
        { text: 'Startnummer', value: 'order', sortable: false, align: 'center' },
        { text: 'Hinzufügen', value: 'add', sortable: false, align: 'center' },
        { text: 'Entfernen', value: 'del', sortable: false, align: 'center' }
      ]"
      :items-per-page="-1"
    >
      <template #item.order="{item}">
        <base-edit-dialog
          :value="item.order"
          :id="item._id"
          label="Reihenfolge"
          @input="({ value, id: iid }) => updateorder(iid, value)"
        />
      </template>
      <template #item.add="{item}">
        <v-btn
          v-if="!item.active && numactive < maxathletes"
          fab
          x-small
          @click="add(item._id)"
        >
          <v-icon>far fa-plus</v-icon>
        </v-btn>
      </template>
      <template #item.del="{item}">
        <v-btn
          v-if="item.active && !item.used"
          fab
          x-small
          @click="del(item._id)"
        >
          <v-icon>far fa-minus</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <h3>Hinweise:</h3>
    <ul>
      <li>Turner, bei denen bereits eine Wertung eingegeben wurde, können nicht mehr entfernt werden.</li>
      <li>Um diese Turner zu entfernen, muss die zugehörige Wertung (über den Ergebnisse-Reiter) gelöscht werden.</li>
      <li>Die Reihenfolge kann durch einen Klick auf die Zahl angepasst werden.</li>
    </ul>
  </div>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'geraet',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    team: {
      type: String,
      required: true
    },
    did: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    d () {
      return this.df?.find(d => d._id === this.did) || {}
    },
    wkteam () {
      return this.e?.teams?.find(t => t.team._id === this.team) || {}
    },
    athletes () {
      return (this.wkteam?.athletes?.map(a => ({
        _id: a.person._id,
        person: a.person,
        name: a.person.familyName + ', ' + a.person.givenName,
        active: !!a.results?.find(r => r._discipline === this.did),
        order: a.results?.find(r => r._discipline === this.did)?.order,
        used: !!a.results?.find(r => r._discipline === this.did)?.final
      })) || []).sort((a, b) => {
        if (a.order && b.order) return a.order < b.order ? -1 : 1
        if (a.order) return -1
        if (b.order) return 1
        if (a.person.familyName !== b.person.familyName) return a.person.familyName < b.person.familyName ? -1 : 1
        return a.person.givenName < b.person.givenName ? -1 : 1
      })
    },
    numactive () {
      return this.athletes.filter(a => a.active).length
    },
    maxactive () {
      return this.athletes.filter(a => a.active).reduce((acc, curr) => Math.max(acc, curr.order), 0)
    },
    maxathletes () {
      switch (this.e?.mode) {
        case 'lp_3aus4':
        case 'wk_score4':
        case 'lf_4aus4':
          return 4
        case 'wk_4aus5':
        case 'lf_4aus5':
          return 5
        case 'test':
          return 10
        default: return 0
      }
    }
  },

  methods: {
    add (person) {
      this.mutate({
        mutation: gql`
          mutation($event: UUID!, $team: UUID!, $person: UUID!, $discipline: UUID!, $order: Int!) {
            StbM2021WkTeamAthleteResultAdd(event: $event, team: $team, person: $person, discipline: $discipline, order: $order) {
              _id
            }
          }
        `,
        variables: {
          event: this.id,
          team: this.team,
          person,
          discipline: this.did,
          order: this.maxactive + 1
        }
      })
    },
    del (person) {
      this.mutate({
        mutation: gql`
          mutation($event: UUID!, $team: UUID!, $person: UUID!, $discipline: UUID!) {
            StbM2021WkTeamAthleteResultDelete(event: $event, team: $team, person: $person, discipline: $discipline)
          }
        `,
        variables: {
          event: this.id,
          team: this.team,
          person,
          discipline: this.did
        }
      })
    },
    updateorder (person, order) {
      order = parseInt(order)
      if (isNaN(order)) return

      if (order < 1) order = 1
      else if (order > this.numactive) order = this.numactive

      this.mutate({
        mutation: gql`
          mutation($event: UUID!, $team: UUID!, $person: UUID!, $discipline: UUID!, $order: Int!) {
            StbM2021WkTeamAthleteResultOrderUpdate(event: $event, team: $team, person: $person, discipline: $discipline, order: $order) {
              _id
            }
          }
        `,
        variables: {
          event: this.id,
          team: this.team,
          person,
          discipline: this.did,
          order
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
